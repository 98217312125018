<template>
  <div class="container">
    <PublicHeader :totalName="$t('trustShip').contractTitle" />
    <div class="content" id="html2canvas" ref="html2canvas">
      <div class="title">{{ $t('trustShip').contractTitle }}</div>
      {{ $t('trustShip').contractInfo1 }}
      <br><br>{{ $t('trustShip').contractInfo2 }}
      <br><br>{{ $t('trustShip').contractInfo3 }}
      <br><br>{{ $t('trustShip').contractInfo4 }}<span> {{ Amount }} </span>USDT，{{ $t('trustShip').contractInfo5
      }}<span> {{ Term }} </span>{{ $t('trustShip').contractInfo6 }}。
      <br><br>{{ $t('trustShip').contractInfo7 }}
      <br><br>{{ $t('trustShip').contractInfo8 }}
      <br><br>{{ $t('trustShip').contractInfo9 }}
      <br><br><br>{{ $t('trustShip').contractInfo10 }}
      <br><br>{{ $t('trustShip').contractInfo11 }}
      <br><br>{{ $t('trustShip').contractInfo12 }}
      <br><br>{{ $t('trustShip').contractInfo13 }}
      <br><br>{{ $t('trustShip').contractInfo14 }}
      <br><br>{{ $t('trustShip').contractInfo15 }}
      <br><br>{{ $t('trustShip').contractInfo16 }}
      <br><br>{{ $t('trustShip').contractInfo17 }}
      <br><br>{{ $t('trustShip').contractInfo18 }}
      <br><br><br>{{ $t('trustShip').contractInfo19 }}
      <br><br>{{ $t('trustShip').contractInfo20 }}
      <br><br>{{ $t('trustShip').contractInfo21 }}
      <br><br>{{ $t('trustShip').contractInfo22 }}：<span> {{ Name }} </span>
      <br><br>{{ $t('trustShip').contractInfo23 }}：{{ Time | getLocalDate }}
      <br><br>{{ $t('trustShip').contractInfo24 }}
      <br><br>{{ $t('trustShip').contractInfo25 }}
      <img src="@/assets/img/con_seal.png" width="91" style="margin: -100px 0 0 50px" />
      <!-- <br>{{ $t('trustShip').contractInfo26 }} -->
      <br><br>{{ $t('trustShip').contractInfo23 }}：{{ Time | getLocalDate }}
      <br><br>{{ $t('trustShip').contractInfo27 }}
    </div>
    <div class="footer" @click="exportPDF"><img src="@/assets/img/download.png" width="18" /><span>{{
      $t('trustShip').downLoad }}</span></div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'
import { downloadPDF } from '@/utils/validate'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      Amount: '',
      Term: '',
      Name: '',
      Time: ''
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    if (this.$route.query) {
      this.Name = this.$route.query.Name
      this.Amount = this.$route.query.Amount
      this.Term = this.$route.query.Term
      this.Time = this.$route.query.Time
    }
  },
  methods: {
    exportPDF() {
      downloadPDF(document.querySelector('#html2canvas'), '加密资产托管合同')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding-top: 50px;
  background: #fff;

  .content {
    padding: 20px 13px;
    font-size: 15px;
    color: #333333;
    line-height: 21px;
    letter-spacing: 0.1px;

    .title {
      text-align: center;
      font-weight: 800;
      font-size: 17px;
      color: #392F2F;
      padding-bottom: 20px;
    }


    span {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .footer {
    margin-top: 50px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 -1px 1px 1px rgba(0, 0, 0, 0.05);
    font-size: 15px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 5px;
    }

  }

}
</style>
